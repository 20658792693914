import React, { useEffect, useState } from 'react';
import { Table, Icon } from 'antd';
import { GET_ENTITIES } from 'app-redux/actions';
import { createAction } from '@arivaa-react/redux/helpers/action';
import preprocess from 'containers/preprocess';
import Link from '@arivaa-react/components/link';
import { mobileAndTabletcheck } from '@arivaa-react/helpers/web';
import { renderPercentage, renderPriceInBTC } from '../../helpers/table';

let apiMap = {};
/**
 * @description Sample Component
 * @type component
 * @author Jasjot
 */
const Main = ({
  data,
  api,
  bitcoinPrices,
  title,
  sats,
  getData,
  assetColumnName,
  mobileAssetColumnName,
  showSymbol,
  search,
  showTitle,
  tableKey,
  hideColumns,
}) => {
  const isMobile = mobileAndTabletcheck();
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      title: (isMobile && mobileAssetColumnName) || assetColumnName || 'Asset',
      dataIndex: 'key',
      key: 'key',
      width: isMobile ? 120 : 300,
      render: (text, record) => {
        return (
          <Link
            routeKey="chart"
            routeParams={{
              symbol: record.key,
              symboltype:
                tableKey instanceof Function ? tableKey(record.key) : tableKey,
            }}
            className="asset-type"
          >
            {isMobile ? '' : record.name}
            {showSymbol || isMobile
              ? isMobile
                ? `${record.mobileName || record.key}`
                : ` (${record.key})`
              : ''}
          </Link>
        );
      },
    },
    {
      title: 'Price',
      dataIndex: 'today',
      key: 'today',
      width: 150,
      render: renderPriceInBTC(sats),
    },
    {
      title: 'Day',
      dataIndex: 'today',
      key: 'todayPercentage',
      render: renderPercentage('today', sats),
    },
    {
      title: 'Week',
      dataIndex: 'weekly',
      key: 'weekly',
      render: renderPercentage('weekly', sats),
    },
    {
      title: 'Month',
      dataIndex: 'monthly',
      key: 'monthly',
      render: renderPercentage('monthly', sats),
    },
    {
      title: 'YTD',
      dataIndex: 'ytd',
      key: 'ytd',
      render: renderPercentage('ytd', sats),
    },
    {
      title: '1Y',
      dataIndex: 'yearly',
      key: 'yearly',
      render: renderPercentage('yearly', sats),
    },
    {
      title: '3Y',
      dataIndex: 'yearly3',
      key: 'yearly3',
      render: renderPercentage('yearly3', sats),
    },
    {
      title: '5Y',
      dataIndex: 'yearly5',
      key: 'yearly5',
      render: renderPercentage('yearly5', sats),
    },
    //   {
    //     title: '10 Yr',
    //     dataIndex: 'tenYear',
    //     key: 'tenYear',
    //     render: renderPercentage,
    //   },
  ].filter(({ key }) => {
    return hideColumns ? hideColumns.indexOf(key) === -1 : true;
  });
  useEffect(() => {
    const callApi = async (refresh) => {
      if ((api && data.length === 0 && !apiMap[api]) || refresh) {
        !refresh && setLoading(true);
        apiMap[api] = {
          loaded: false,
        };
        try {
          await getData({
            entityName: api,
          });
          apiMap[api].loaded = true;
        } catch (e) {
          console.error('Error while getting data for stats table', {
            api,
            title,
            e,
          });
          delete apiMap[api];
        }
        !refresh && setLoading(false);
      } else {
        if (apiMap[api] && !apiMap[api].loaded) {
          setLoading(true);
          setInterval(() => {
            if (apiMap[api].loaded) {
              !refresh && setLoading(false);
            }
          }, 500);
        }
      }
    };
    if (api) {
      setInterval(() => {
        callApi(true);
      }, 60000);
      callApi();
    }
    /*return () => {
      clearInterval(interval);
    };*/
  }, []);
  return search && data.length === 0 ? null : (
    <div className="table metals">
      {showTitle && (
        <p className={'label ' + (api ? '' : 'no-space')}>{title}</p>
      )}

      {api ? (
        <Table
          loading={loading}
          columns={columns}
          dataSource={data}
          pagination={false}
        />
      ) : null}
    </div>
  );
};

/**
 * Bind Redux Actions
 * @param dispatch
 * @returns {{Object}}
 */
const bindAction = (dispatch) => {
  return {
    getData: (data) => {
      const action = createAction(GET_ENTITIES, data);
      action.type = action.type + '_timeline';
      return dispatch(action);
    },
  };
};
/**
 * Bind State to props
 * @param dispatch
 * @returns {{Object}}
 */
const mapStateToProps = (
  { timeline, bitcoinStats },
  { api, dataFormatter, search, showSymbol, disableSortByOrder, filterData }
) => {
  let data = timeline[api];
  if (dataFormatter instanceof Function) {
    data = dataFormatter(data);
  }
  data = data || [];
  if (search) {
    data = data.filter(({ name: type, key: symbol }) => {
      type = type || '';
      symbol = symbol || '';
      const searchText = search.toLowerCase();
      return (
        type.toLowerCase().indexOf(searchText) !== -1 ||
        (showSymbol && symbol.toLowerCase().indexOf(searchText) !== -1)
      );
    });
  }
  if (!disableSortByOrder) {
    data = data.sort((a, b) => a.showOrder - b.showOrder);
  }
  if (filterData instanceof Function) {
    data = data.filter(filterData);
  }
  return {
    data,
    bitcoinPrices: (bitcoinStats && bitcoinStats.prices) || {},
  };
};

Main.displayName = 'Stats-Table';

//Pre process the container with Redux Plugins
export default preprocess(Main, {
  connect: [mapStateToProps, bindAction],
  localize: true,
});
